import 'styles/globals.css'
import type {AppProps} from 'next/app'
import type {NextPage} from 'next'
import {useState} from 'react'
import {ErrorBoundary} from 'react-error-boundary'
import {QueryClientProvider} from '@tanstack/react-query'
import {ReactQueryDevtools} from '@tanstack/react-query-devtools'
import queryClient from 'lib/query-client'
import ErrorFallback from 'components/error-fallback'
import Layout from 'components/layout'
import FacebookChat from 'components/fb-chat'
import Toast from 'components/toast'
import OrderPopup from '../components/orders/orderPopup'

type NextPageWithLayout = NextPage & {
  getLayout?: (page: React.ReactElement) => React.ReactNode
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

function TraderApp({Component, pageProps}: AppPropsWithLayout) {
  const [isError, setIsError] = useState(false)

  const isProd = process.env.NODE_ENV === 'production'
  // const isProd = true
  const getLayout = Component.getLayout ?? ((page) => <Layout>{page}</Layout>)

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => setIsError(false)}
      resetKeys={[isError]}
    >
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        {getLayout(<Component {...pageProps} />)}
        <Toast />
        {isProd ? <FacebookChat /> : null}
        <OrderPopup />
      </QueryClientProvider>
    </ErrorBoundary>
  )
}

export default TraderApp
