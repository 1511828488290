type StakeLogoProps = {} & JSX.IntrinsicElements['svg']

function StakeIcon(props: StakeLogoProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 447 222"
      width={30}
      height={30}
      {...props}
    >
      <g transform="matrix(1.1284878,0,0,1.1216599,5.6467546e-4,6.2903155e-4)">
        <path
          d="M25.68,56.89C25.58,30.42,42.52,15.74,73.62,15.63C96,15.55,100,30.19,100,35.51c0,10.19-14.42,21-14.42,21s0.8,6.35,13.18,6.3c12.38-0.05,24.44-8.22,24.37-28.67C123.07,10.65,98.46-0.09,74.13,0C53.78,0.07-0.19,2.93,0,56.51c0.18,47.59,90,51.79,90.07,82.26c0.12,33.09-37,42-49.56,42c-12.56,0-17.67-8.89-17.69-13.77c-0.09-26.8,26.58-34.26,26.58-34.26c0-2-1.56-10.91-11.89-10.87c-26.61,0.05-37.21,22.93-37.13,45.27c0.07,19,13.5,30.86,33.78,30.78c38.78-0.14,82.51-19.06,82.35-61.08C116.37,97.54,25.79,87.28,25.68,56.89Z"
          fill="currentColor"
        />
        <path
          d="m395.37,162.18c-0.31-0.75-1.18-0.57-2.33,0.38c-4.4,3.63-14.46,13.91-38,14c-42.92,0.16-50.37-70.58-50.37-70.58s32.71-24.42,37.82-34.27c5.11-9.85-11.15-12-11.15-12s-22.88,27.84-39.1,36C294,83.6,306,56,306.69,40.13c0.69-15.87-19.11-12-22.27-10.48c0,6.93-17.49,69.84-23.86,104.42c-3.75,6-9.11,12.86-13.91,12.87c-2.78,0-3.8-5.15-3.83-12.53c0-10.24,5.64-26.65,5.6-36.62c0-6.9-3.17-7.31-5.9-7.3c-0.59,0-3.87,0.09-4.47,0.09c-7,0-4.7-6-11-5.94c-19.28,0.07-43.84,21.12-44.91,52.89c-6.51,4.31-15.62,9.74-20.27,9.76c-4.88,0-6.09-4.51-6.1-8.41c0-6.85,11.08-47.55,11.08-47.55s14.11-3.62,20.27-4.81c4.66-0.91,6-1.25,7.81-3.5c1.81-2.25,5.35-6.9,8.21-11.08c2.86-4.18,0.05-7.41-5.24-7.39c-6.94,0-25.51,4.4-25.51,4.4s8.92-38.46,8.91-39.24c-0.01-0.78-1-1.19-2.51-1.18c-3.38,0-9.41,1.82-13.27,3.2c-5.91,2.11-10.72,9.35-11.69,12.72c-0.97,3.37-7.65,29.76-7.65,29.76s-35.77,12.35-40.66,14.2a0.74,0.74,0,0,0-0.5,0.7c0,0.32,4.09,16,12.48,15.94c6,0,23.63-7.22,23.63-7.22s-9.06,36-9,48c0,7.7,3.63,16.72,18.67,16.67c14,0,26.25-7.23,33.11-12.26c3.75,9.49,12.61,12.09,18.66,12.07c13.56,0,24-10.82,25.34-12.27c1.76,4.16,5.91,12.15,15.39,12.12c5.36,0,10.91-6,15-11.82a23.38,23.38,0,0,0,0.05,3.63c1.64,14.92,23.79,6.15,25,4.07c0.75-10.79,0.28-32.85,4.59-46.47c5.72,46.46,27.42,77.71,66.43,77.57c21.81-0.08,33-6.35,37.63-11.52a15.9,15.9,0,0,0,3.37-15.44z M208.2,146.86c-18.73,0.07,5.73-48.48,21.71-48.54c0.02,6.26-0.33,48.47-21.71,48.54z"
          fill="currentColor"
        />
        <path
          d="m360.26,161.74c16.91-0.06,30-11.19,32.4-14.81c3.1-4.71-3.6-11.82-5.73-11.7c-5.34,5.11-10.92,11.29-23.45,11.33c-14.75,0.06-11.41-16.17-11.41-16.17s28.39,3.67,39.8-16.74c3.65-6.53,3.8-15.56,2.33-19.45c-1.47-3.89-9.73-11.09-22.87-10c-16.14,1.36-36.48,18.19-40.95,38.4c-3.59,16.26,3.2,39.24,29.88,39.14z m18-68.1c2.66-0.09,2.42,4.29,1.71,8.87c-1,6.21-9.53,22-25.81,21.38c1.13-9.19,13.24-29.89,24.13-30.25z"
          fill="currentColor"
        />
      </g>
    </svg>
  )
}

export default StakeIcon
