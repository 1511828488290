/* eslint-disable @next/next/inline-script-id */
'use client'

import {useEffect} from 'react'
declare global {
  interface Window {
    FB: any
  }
}
const FacebookChat: React.FC = () => {
  useEffect(() => {
    // Tạo thẻ div chat nếu chưa có
    let chatbox = document.getElementById('fb-customer-chat')
    if (!chatbox) {
      chatbox = document.createElement('div')
      chatbox.id = 'fb-customer-chat'
      chatbox.className = 'fb-customerchat'
      chatbox.setAttribute('page_id', '341867329270473') // Thay bằng Page ID của bạn
      chatbox.setAttribute('attribution', 'biz_inbox')
      document.body.appendChild(chatbox)
    }

    // Kiểm tra nếu SDK đã tải thì không tải lại
    if (!document.getElementById('facebook-jssdk')) {
      const script = document.createElement('script')
      script.id = 'facebook-jssdk'
      script.src =
        'https://connect.facebook.net/vi_VN/sdk.js#xfbml=1&version=v18.0' // Không dùng xfbml.customerchat.js
      document.body.appendChild(script)
    }

    // Đợi SDK tải xong rồi khởi tạo Messenger
    setTimeout(() => {
      if (window.FB) {
        window.FB.init({
          xfbml: true,
          version: 'v18.0',
        })
        window.FB.XFBML.parse()
      }
    }, 3000) // Đợi 3s để đảm bảo SDK đã tải xong
  }, [])

  return <div id="fb-root"></div>
}

export default FacebookChat
